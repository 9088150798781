import React, { Component } from "react"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import SEO from "../components/seo"

import Pdf from "../documents/ADC_Application.pdf"
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

export class carpenter extends Component {
  render() {
    const { classes } = this.props
    return (
      <>
        <Layout>
          <SEO
            title="Home"
            keywords={[`adc`, `carpenter`, `career`, `job`, `alaska`]}
          />
          <div className="container-body">
            <div>
              <Typography variant="h3" gutterBottom>
                {" "}
                Carpenter
              </Typography>
              <Typography variant="h4" gutterBottom>
                {" "}
                Job Summary
              </Typography>
              <p>
                We are looking for experienced carpenter to join our team. The
                ideal candidate will have his or her own tools.
              </p>
              <h4> Job description </h4>
              <ul>
                <li>
                  Working knowledge of carpentry and use of hand, power, and
                  pneumatic tools
                </li>
                <li>
                  Able to perform but not limited to: layouts, rough framing,
                  door and window install, exterior trim, interior trim,
                  roofing,{" "}
                </li>
                <li>
                  Ability to read and interpret construction blueprints and
                  drawings
                </li>
                <li>Able to solve carpentry related problems</li>
                <li>
                  {" "}
                  This list is not all inclusive (Other duties as required)
                </li>
              </ul>
              <h4> Requirements</h4>
              <ul>
                <li>
                  {" "}
                  Able to work up to seven days a week, twelve hours a day.{" "}
                </li>
                <li> Minimum of 2 years carpentry experience</li>
                <li>
                  {" "}
                  Pass a drug test (provided at no cost to the candidate)
                </li>
                <li>
                  {" "}
                  Self-motivated, able to follow direction and must have good
                  communication skills{" "}
                </li>
                <li> Must be able to lift 80 pounds </li>
                <li>
                  Punctual with reliable transportation and a valid driver’s
                  license
                </li>
                <li> Able to understand basic building code requirements</li>
                <li>
                  {" "}
                  Must be safety conscious and adhere to company & OSHA
                  requirements
                </li>
                <li> Works well with others and can work independently</li>
              </ul>

              <h3>Application Process</h3>

              <p>
                {" "}
                We encourage you to apply if interested in this position! Please
                download our application form and once completed submit your
                signed application, either{" "}
                <a href=" mailto:info@adcalaska.com">
                  {" "}
                  through email at info@adcalaska.com{" "}
                </a>{" "}
                or fax it to (907) 357-8630{" "}
              </p>
              <div className="buttonContainer">
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  href={Pdf}
                  download
                >
                  Fillable PDF Application
                </Button>
              </div>
              <br />
              <div className="buttonContainer">
                <Button
                  size="medium"
                  variant="outlined"
                  color="primary"
                  href="mailto:info@adcalaska.com"
                >
                  Attach and Submit{" "}
                </Button>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default carpenter
